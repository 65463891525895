
import * as Sentry from '@sentry/vue'
import { Component } from 'vue-property-decorator'
import SavageAimMixin from '@/mixins/savage_aim_mixin'

@Component
export default class TeamAdd extends SavageAimMixin {
  joinError = ''

  inviteCode(): string {
    return (this.$refs.inviteCode as HTMLInputElement).value
  }

  mounted(): void {
    document.title = 'Add Team - Savage Aim'
  }

  url(): string {
    return `/backend/api/team/join/${this.inviteCode()}/`
  }

  async joinTeam(): Promise<void> {
    // Check if the invite code is valid using the HEAD method
    this.joinError = ''

    try {
      const response = await fetch(this.url())
      if (response.ok) {
        // If the code is valid, redirect to the page
        this.$router.push(`/team/join/${this.inviteCode()}/`)
      }
      else {
        this.joinError = 'Invalid invite code! Please make sure you entered it correctly!'
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Team.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }
}
